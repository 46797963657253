<template>
  <Cond :where="where" @refresh="refresh">
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      size="small"
      v-loading="where.loading"
    >
      <el-table-column prop="id" label="ID" width="180"> </el-table-column>
      <el-table-column prop="module_name" label="模块名"> </el-table-column>
      <el-table-column prop="module_desc" label="模块描述"> </el-table-column>
      <el-table-column prop="source" label="平台">
        <template slot-scope="scope">
          <div v-if="scope.row.source == 1">
            <el-tag type="primary" effect="dark" size="small">SHOPEE</el-tag>
          </div>
          <div v-else-if="scope.row.source == 2">
            <el-tag type="success" effect="dark" size="small">LAZADA</el-tag>
          </div>
          <div v-else-if="scope.row.source == 3">
            <el-tag type="success" effect="dark" size="small">Tiktok</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间">
        <template slot-scope="scope">
          {{ $func.format(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <div>
            <el-button @click="openmodule(scope.row)" type="text" size="small"
              >编辑</el-button
            >
          </div>
          <div>
            <el-popconfirm
              title="确定要删除吗？"
              @confirm="moduledelete(scope.row)"
            >
              <el-button type="text" size="small" slot="reference"
                >删除</el-button
              >
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mgt10 text-center">
      <el-pagination
        @current-change="loadList"
        :current-page.sync="where.page"
        background
        layout="prev, pager, next"
        :page-size="where.pageSize"
        :total="where.total"
      >
      </el-pagination>
    </div>
    <Moduleform ref="module" @refresh="loadList"></Moduleform>
  </Cond>
</template>
<script>
import Cond from "./systemmodulecond.vue";
import Moduleform from "@/components/backend/system/module.vue";
export default {
  components: {
    Cond,
    Moduleform,
  },
  data() {
    return {
      url: {
        modulelist: "/sysadminsystem/modulelist",
        moduledelete: "/sysadminsystem/moduledelete",
      },
      where: {
        source: 1,
        module_name: "",
        page: 1,
        pageSize: 50,
        total: 0,
        loading: false,
      },
      tableData: [],
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    refresh: function () {
      this.where.page = 1;
      this.loadList();
    },
    loadList: function () {
      this.where.loading = true;
      this.$rq.postAction(this.url.modulelist, this.where).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.tableData = [];
          for (var i in rs.list) {
            this.tableData.push(rs.list[i]);
          }
          this.where.total = parseInt(rs.pageInfo.total);
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
    moduledelete: function (row) {
      this.where.loading = true;
      this.$rq.postAction(this.url.moduledelete, { id: row.id }).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.loadList();
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
    openmodule: function (row) {
      this.$refs.module.show(row);
    },
  },
};
</script>