<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="visiable"
    :before-close="handleClose"
  >
    <el-form
      ref="form"
      :rules="rules"
      :model="form"
      size="small"
      label-position="top"
    >
      <el-form-item label="平台" prop="source">
        <el-select
          v-model="form.source"
          placeholder="请选择"
        >
          <el-option :value="1" label="Shopee平台"></el-option>
          <el-option :value="2" label="Lazada平台"></el-option>
          <el-option :value="3" label="Tiktok平台"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模块名称" prop="module_name">
        <el-input
          v-model="form.module_name"
          placeholder="请输入模块名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否主模块" prop="is_master">
        <el-switch
          v-model="form.is_master"
          active-text="主模块"
          inactive-text="非主模块"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="模块描述" prop="module_desc">
        <el-input
          v-model="form.module_desc"
          type="textarea"
          :rows="3"
          placeholder="请输入模块描述"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="form.loading" @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      title: "添加模块",
      visiable: false,
      form: this.getDefaultData(),
      rules: {
        module_name: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
        ],
        source: [
          {
            validator: (rule, value, callback) => {
              if (value == "" || value == 0) {
                return callback(new Error("请选择平台"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
      },
      url: {
        moduleadd: "/sysadminsystem/moduleadd",
      },
    };
  },
  created() {},
  methods: {
    getDefaultData: function () {
      return {
        id: 0,
        module_name: "",
        module_desc: "",
        source: 1,
        is_master: 0,
        loading: false,
      };
    },
    handleClose: function () {
      this.$refs.form.resetFields();
      this.visiable = false;
    },
    show: function (row) {
      if (row) {
        this.title = "编辑模块";
        this.form = this.$func.copy(row);
      } else {
        this.title = "添加模块";
        this.form = this.getDefaultData();
      }
      this.visiable = true;
    },
    submit: function () {
      this.$refs.form.validate((v) => {
        if (v) {
          this.form.loading = true;
          this.$rq.postAction(this.url.moduleadd, this.form).then((rs) => {
            this.form.loading = false;
            if (rs.code == 1) {
              this.visiable = false;
              this.$message({
                type: "success",
                message: rs.msg,
              });
              this.$emit("refresh");
            } else {
              this.$message({
                type: "warning",
                message: rs.msg,
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.myview >>> .el-checkbox {
  flex: 1;
  margin-left: 10px;
  margin-bottom: 8px;
}
.myview >>> .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
</style>